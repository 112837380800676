import { createApp, defineComponent, h } from 'vue'
import HomePage from './HomePage.vue'
import EditorPage from './EditorPage.vue'
import NotFoundPage from './NotFoundPage.vue'

const App = defineComponent({
  data() {
    return {
      currentRoute: window.location.pathname,
    }
  },
  computed: {
    currentComponent() {
      if (this.currentRoute == '/') {
        return HomePage
      }
      if (this.currentRoute == '/editor') {
        return EditorPage
      }
      return NotFoundPage
    },
  },
  render() {
    return h(this.currentComponent)
  },
})

createApp(App).mount('#app')
