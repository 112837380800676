
import { defineComponent } from 'vue'

export default defineComponent({
  mounted: () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    SwaggerEditorBundle({
      url: '/swagger.yml',
      dom_id: '#swagger',
      presets: [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        SwaggerEditorStandalonePreset,
      ],
      layout: 'StandaloneLayout',
    })
  },
})
